import { Button, ButtonProps, Chip, CircularProgress, IconButton, Tooltip } from "@mui/material"
import { Link } from "react-router-dom"
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import DoneIcon from '@mui/icons-material/Done'
import SendIcon from '@mui/icons-material/Send'
import SearchIcon from '@mui/icons-material/Search'
import React, { FC, isValidElement, useState } from "react"
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { CSVDownloadButtonProps } from "../../Interfaces/CSVDownloadButton"
import { getCurrentTimestamp } from "../../Utils/Date"
import { flexRender, getCoreRowModel, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table"
import { renderToString } from 'react-dom/server'

export const CreateButton = ({ path, className }: { path: string, className?: string }) => {
    return (
        <Link to={path} className={className}>
            <Button size="small" variant="contained" endIcon={<PlaylistAddIcon />}>新規作成</Button>
        </Link>
    )
}

export const AddButton = ({ path, className }: { path: string, className?: string }) => {
    return (
        <Link to={path} className={className}>
            <Button size="small" variant="contained" endIcon={<PlaylistAddIcon />}>追加</Button>
        </Link>
    )
}

export const SubmitButton = () => {
    return (
        <Button 
            type="submit" 
            variant="contained"
            endIcon={<SendIcon />}
        >
            送信
        </Button>
    )
}

export const ModalSubmitButton = ({ isLoading, onClick }: { isLoading: boolean, onClick: (event: React.FormEvent) => void }) => {
    return (
        <>
            <Button 
                type="button" 
                variant="contained"
                endIcon={<SendIcon />}
                onClick={onClick}
                disabled={isLoading}
            >
                送信
            </Button>
            {isLoading && (
                <CircularProgress
                    size={24}
                    sx={{
                        position: 'relative',
                        top: '8px',
                        left: '-54px',
                    }}
                />
            )}
        </>
    )
}

export const SearchButton = () => {
    return (
        <Button 
            type="submit" 
            variant="contained"
            endIcon={<SearchIcon />}
        >
            検索
        </Button>
    )
}

export const CopyButton = ({text = ''}: {text?: string}) => {
    const [openTip, setOpenTip] = useState<boolean>(false);
  
    const handleDelete = (): void => {
        setOpenTip(false)
    }

    const handleOpenTip = (): void => {
        setOpenTip(true)
  
        setTimeout(() => {
            setOpenTip(false)
        }, 2000)
    }

    return (
        <>
            <CopyToClipboard text={text}>
                <IconButton
                    disabled={text === ''}
                    onClick={handleOpenTip}
                >
                    <ContentCopyIcon sx={{ fontSize: '.8rem' }} />
                </IconButton>
            </CopyToClipboard>
            { openTip ? <Chip label="Copied!" color="primary" size="small" deleteIcon={<DoneIcon />} onDelete={handleDelete} sx={{ fontSize: '.7rem' }} /> : ''}
        </>
    )
}

interface CircularProgressButtonProp {
    isLoading: boolean
}
export const CircularProgressButton: FC<CircularProgressButtonProp & ButtonProps> = ({ isLoading, ...props }) => {
    return (
        <>
            <Button 
                type={props.type ? props.type : 'button'}
                variant={props.variant ? props.variant : 'contained'}
                disabled={isLoading} 
                onClick={props.onClick} 
                endIcon={props.endIcon}
                size={props.size ? props.size : 'small'}
                sx={ props.sx ? props.sx : { textTransform: 'none' }}
            >
                {props.children}
            </Button>
            {isLoading && (
                <CircularProgress
                    size={24}
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                    }}
                />
            )}
        </>
    )
}

export const CSVDownloadButton = <T extends Record<string, unknown>>({ columns, data, filename, className }: CSVDownloadButtonProps<T>) => {
    if (!data || data.length === 0) return '';

    // ヘッダーを columns から生成
    const headers = columns.map((col: any) => '"' + col.header + '"').join(",");

    // ネストされたプロパティにアクセスできるようにする
    const getValueFromPath = (obj: any, path: string) => {
        return path.split('.').reduce((acc, part) => acc && acc[part], obj);
    }

    // data の内容を columns に基づいて取得
    const rows = data.map(row => 
        columns.map((col: any) => {
            const value = getValueFromPath(row, col.key)  // 元データから値を取得
            return col.fn ? '"' + col.fn(value) + '"' : '"' + value + '"' // fn がある場合は加工処理
        }).join(',')
    ).join('\n')

    const csvData = `${headers}\n${rows}`

    const downloadCSV = () => {
        const timestamp = getCurrentTimestamp()
        const fullFilename = `${filename}_${timestamp}.csv`
        const blob = new Blob([csvData], { type: "text/csv"})
        const url = URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fullFilename)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
    }

    return (
        <div className={`inline ${className}`}>
            <Button 
                size="small" 
                variant="contained"
                endIcon={<FileDownloadIcon />}
                onClick={downloadCSV}
            >
                CSVダウンロード
            </Button>
        </div> 
    )
}

